import cadastroServicos from '../../../assets/icon1.png'
import pedidosClientes from '../../../assets/icon2.png'
import liberePedido from '../../../assets/icon3.png'
import './styles.css'
import logo from '../../../assets/logo.png';
export function CadastroPedido() {
    return (
      <>
        <div className='cultura-header2'>
              <div>
                <h1 style={{color: '#0F131E', textTransform: 'uppercase'}}>SAFETY</h1>
                <h3>Gestão Segurança no Trabalho</h3>
              </div>

              <img src={logo} alt="CEO" className='logo-top' />
            </div>
      <div className='icons-container'>
     </div>
        <section className="prestadorServicos">
            <div className="cadastroServicos">
                <img src={pedidosClientes} alt="Cadastre seus serviços" />
                <h3>Barreira Física</h3>
                <p>Identificamos e criamos propostas de solução através de barreiras físicas que possam colocar em risco a segurança das pessoas</p>
            </div>
            <div className="pedidosClientes">
                <img src={cadastroServicos} alt="Cadastre seus serviços" />
                <h3>Barreira Sistêmica</h3>
                <p>Implantamos metodologia e soluções que atuam diretamente na prevenção de acidentes</p>
            </div>
            <div className="liberePedido">
                <img src={liberePedido} alt="Cadastre seus serviços" />
                <h3>Barreira Comportamental</h3>
                <p>Mudamos comportamento das pessoas com foco na prevenção de acidentes e trabalho seguro</p>
            </div>
        </section>
        </>
    )
}
