export const phoneNumberMask = (input: string): string => {
  if (input === '' || input === null) {
    return input;
  }

  const digitsOnlyInput = input.replace(/\D/g, '');

  let value = digitsOnlyInput.replace(/(\d{2})(\d)/, '$1) $2');

  if (digitsOnlyInput.length > 10) {
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
  } else {
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return '('.concat(value).replace(/(-\d{4})\d*$/, '$1');
};
