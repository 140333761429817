import '../../global.css'

import {  Header } from "../../pages/Home/Header";
import { Service } from "./Servicos";
import { Professional } from './Professional';
import { Priority } from '../Home/Priority';
import { Footer } from '../../components/Footer';
import { CadastroPedido } from './CadastroPedido';
import { FooterBg } from './FooterBg';
import { Solution } from './Solution';
import { Empresas } from './Empresas';
import { SolutionFooter } from './SolutionFooter';

export default function App() {
  return (
    <>
    <Header />
    <Service />
    <Professional />
    <Priority />
    <Solution />
    <CadastroPedido />
    <SolutionFooter />
    <Empresas />
    <FooterBg />
    <Footer />
    </>
  );
}
