import "./styles.css";

import { motion } from "framer-motion";
import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { phoneNumberMask } from "../../../utils/masks";
import {
  SERVICEID_EMAILJS,
  TEMPLATEID_EMAILJS,
  PUBLIC_KEY_EMAILJS,
} from "../../../config";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export function Service() {
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    whatsapp: "",
    reply_to: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => emailjs.init(PUBLIC_KEY_EMAILJS), []);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    setLoading(true);

    emailjs
      .send(SERVICEID_EMAILJS, TEMPLATEID_EMAILJS, toSend, PUBLIC_KEY_EMAILJS)
      .then((response) => {
        alert("Mensagem enviada, em breve entraremos em contato!");

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "formSubmission",
          formType: "Entre em contato",
        });

        setToSend({
          from_name: "",
          to_name: "",
          message: "",
          whatsapp: "",
          reply_to: "",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setToSend({ ...toSend, [event.target.name]: event.target.value });
  }

  return (
    <section className="serviceComponent" id="servicos">
      <div className="textContainers">
        <div className="textButtonHeader">
          <h2 style={{ color: "#0F131E", textTransform: "uppercase" }}>
            Quem somos
          </h2>
          <p>
            Somos a CEO Consulting, uma empresa de consultoria empresarial que
            transforma resultados através do uso de método e soluções
            tecnológicas. Acreditamos que processos são transformados a partir
            de mudança de comportamento e atitude.
          </p>
        </div>

        <div className="textButtonHeader">
          <h2
            style={{
              color: "#0F131E",
              textTransform: "uppercase",
              textAlign: "right",
            }}
          >
            Propósito
          </h2>
          <p>
            Nosso propósito é deixar mais competitivas as empresas brasileiras
            no cenário global reduzindo custos e despesas, aumentando a
            produtividade dos seus ativos e a segurança dos colaboradores,
            aplicando métodos de gestão, ferramentas e transformando
            comportamento das pessoas.
          </p>
        </div>

        <div className="textButtonHeader">
          <h2 style={{ color: "#0F131E", textTransform: "uppercase" }}>
            Como atuamos
          </h2>
          <p>
            Utilizando soluções modernas e inovadoras para atingir nível de
            competitividade classe mundial Influenciamos as pessoas a mudarem
            seu comportamento em busca de resultados Conhecemos os problemas e
            apoiamos na construção e aplicação das soluções, através do uso de
            metodologia e tecnologia.
          </p>
        </div>
      </div>

      <motion.div
        className="form-container"
        initial={{ y: 400 }}
        animate={{
          y: 0,
        }}
        transition={{ delay: 1, duration: 0.8 }}
      >
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend>
              <h2>Entre em contato</h2>
            </legend>
            <div className="field">
              <label htmlFor="to_name">Nome</label>
              <input
                value={toSend.to_name}
                required
                type="text"
                name="to_name"
                id="name"
                onChange={handleInputChange}
                placeholder="Digite seu nome"
              />
            </div>

            <div className="field-group">
              <div className="field">
                <label htmlFor="reply_to">E-mail</label>
                <input
                  value={toSend.reply_to}
                  required
                  type="email"
                  name="reply_to"
                  id="email"
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </div>
              <div className="field">
                <label htmlFor="whatsapp">Telefone</label>
                <input
                  required
                  value={phoneNumberMask(toSend.whatsapp)}
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  onChange={handleInputChange}
                  placeholder="Telefone"
                />
              </div>

              <div className="field">
                <label htmlFor="message">Mensagem</label>
                <textarea
                  value={toSend.message}
                  required
                  rows={4}
                  name="message"
                  id="whatsapp"
                  placeholder="Digite uma mensagem"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </fieldset>

          <button type="submit" disabled={loading}>
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </motion.div>
    </section>
  );
}
