import './styles.css'

import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import brand1 from '../../../assets/brands/brand1.png'
import brand2 from '../../../assets/brands/brand2.png'
import brand3 from '../../../assets/brands/brand3.png'

const displayCount = 3

export function Empresas() {

  return (
    <section className="brandsContainer" >

      <h2>Empresas parceiras</h2>

      <Carousel
        centerMode
        showThumbs={false}
        infiniteLoop
        autoPlay
        showStatus={false}
        centerSlidePercentage={100 / displayCount}
      >
                  <div className='item'>
                    <img src={brand1} alt="Odonto Company"  />
                    </div>
                    <div className='item'>
                    <img src={brand2} alt="Gerdau"  />
                    </div>
                    <div className='item'>
                    <img src={brand3} alt="Silat"  />
                    </div>
        </Carousel>
    </section >
  )
}
