import { motion } from 'framer-motion';
import logoImg from '../../../assets/logo_extended.png'
import './styles.css';

export function Header() {
  return (
    <section className="secondHeader">
      <motion.div
      initial={{scale: 0.8, opacity: 0}}
       animate={{ scale: 1, opacity: 1 }}
       transition={{ duration: 1 }}>
        <img className='logo-header' src={logoImg} alt="CEO Consulting" />
      </motion.div>

      <div className='textButtonHeader'>
        <h2 id='fdkj'>Solução certa para seu negócio</h2>
      </div>
    </section>
  )
}
