import { Carousel } from 'react-responsive-carousel'
import './styles.css'

export function Solution() {

  return (
    <section className="professionalComponent2">
      <Carousel emulateTouch autoPlay showArrows={false} showStatus={false} infiniteLoop useKeyboardArrows showThumbs={false} >
      <div className="textButtonHeader">
         <h2>Sua solução está aqui</h2>
         <h5>Conhecemos os problemas e apoiamos na construção e aplicação das soluções, através do uso de Ferramentas de Gestão.</h5>
      </div>
      <div className="textButtonHeader">
         <h2>Sua solução está aqui</h2>
         <h5>Utilizando soluções modernas e inovadoras para atingir nível de competitividade classe mundial</h5>
      </div>
      <div className="textButtonHeader">
         <h2>Sua solução está aqui</h2>
         <h5> Influenciamos as pessoas a mudarem seu comportamento em busca de resultados</h5>
      </div>
  </Carousel>
    </section >
  )
}
