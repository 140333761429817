import './styles.css'

import logo from '../../../assets/logo.png';
import tree from '../../../assets/tree.png';
import driver from '../../../assets/driver.png';

export function Priority() {
    return (
        <section className="contatoComponent" id="contato">

            <div className="textButtonContact">

            <div className='cultura-header'>
              <div>
                <h1 style={{color: '#0F131E', textTransform: 'uppercase', fontWeight: 'bold'}}>Cultura de Performance</h1>
                <h3>Gestão Método e Resultado</h3>
              </div>

              <img src={logo} alt="CEO" className='logo-top' />
            </div>

            <div className="grid-container">
              <div>
                <h4>Driver’s de trabalho</h4>
              <img src={driver} alt="" className='driver' />
              <p className='subtitle' style={{marginTop: '3rem'}}>Criamos Driver’s de trabalho focados em aumento de competividade. Atuamos no gerenciamento da rotina, na promoção de melhorias e mudança de comportamento</p>
              </div>

              <div>
              <img src={tree} alt="" />
              </div>

               <div>
                <h4>PROBLEMAS QUE SOLUCIONAMOS</h4>

                <p className='title-bold'>Não atendimento a metas</p>
                <p className='subtitle'>Processos incapazes de baterem resultados e desafios. Processo Limitado.</p>

                <p className='title-bold'>Falhas Repetitivas</p>
                <p className='subtitle'>Ocorrências conhecidas que não são tratadas na gestão de rotina</p>

                <p className='title-bold'>Falta de Padrões</p>
                <p className='subtitle'>Diferentes execuções pelas equipe. Predomina experiência individual.</p>

                <p className='title-bold'>Equipamentos com baixa performance</p>
                <p className='subtitle'>As máquina ocasionam redução na entrega das suas metas. Excesso de interrupções.</p>

                <p className='title-bold'>Falta de foco na execução da rotina</p>
                <p className='subtitle'>Sentimento de que se está trabalhando muito para atender os resultados</p>

                <p className='title-bold'>Liderança não consegue tratar problemas</p>
                <p className='subtitle'>Comportamento complacente da Liderança, tratativa "cosmética" de conflitos e problemas</p>
              </div>
            </div>
            </div>
        </section>
    )
}
