import { motion, useAnimation } from 'framer-motion'
import './styles.css'

import { useInView } from "react-intersection-observer";

import { useEffect } from 'react';

import logoImg from '../../../assets/logo_ceo.png';

export function FooterBg() {

  const squareVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0 }
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

    return (
        <div className="secondHeader2">

            <motion.div
         ref={ref}
         animate={controls}
         initial={{scale: 0}}
         variants={squareVariants}>
              <img src={logoImg} alt="CEO Consulting" />
            </motion.div>

        </div>
    )
}


