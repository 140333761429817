import { Link } from 'react-router-dom'
import '../Footer/styles.css'

import { FaPhoneSquare, FaLinkedin, FaEnvelopeSquare } from 'react-icons/fa';

export function Footer() {
    return (
        <>
        <footer className="footer">

        <div>
          <Link  target={"_blank"} to={{pathname: "https://api.whatsapp.com/send?phone=5531997722611"}}>
            <FaPhoneSquare />
          </Link>

          <Link target={"_blank"} to={{pathname: "https://www.linkedin.com/company/ceo-consulting-br"}}>
            <FaLinkedin />
          </Link>

          <Link  target={"_blank"} to={{pathname: "mailto:contato@ceoconsulting.com.br"}}>
            <FaEnvelopeSquare />
          </Link>
        </div>

        <span className="textFooter"><i className="far fa-copyright"></i>2021 - CEO Consulting. Todos os direitos reservados</span><br></br>
        </footer>

        {/* <footer className="footer2">
                <span className="textFooter2">Desenvolvido por @viniciusdev</span>
            </footer> */}
        </>
    )
}
