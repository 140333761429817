import './global.css'

import Routes from './routes';

export function App() {
  return (
    <>
    <Routes />
    </>
  );
}