import { Carousel } from 'react-responsive-carousel'
import './styles.css'

export function SolutionFooter() {
  return (
    <section className="professionalComponent3">
         <Carousel emulateTouch autoPlay showArrows={false} showStatus={false} infiniteLoop useKeyboardArrows showThumbs={false} >

      <div className="dsdms">
         <h2>Somos a sua solução</h2>
         <h5>Potencializamos resultados priorizando utilizar os recursos disponíveis na empresa</h5>
      </div>

      <div className="dsdms">
         <h2>Somos a sua solução</h2>
         <h5>Através do uso de ferramentas e métodos adequados soluções específicas para cada problema</h5>
      </div>

      <div className="dsdms">
         <h2>Somos a sua solução</h2>
         <h5>Vamos construir um ambiente de disciplina operacional e mentalidade vencedora</h5>
      </div>

      <div className="dsdms">
         <h2>Somos a sua solução</h2>
         <h5>Faremos com que sua empresa tenha competitividade classe mundial</h5>
      </div>

      </Carousel>
    </section>
  )
}
