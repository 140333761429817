import './styles.css'

import { Link } from 'react-router-dom';

export function Professional() {

  return (
    <section className="professionalComponent">
      <div className="title-priority">
         <h2>Qual é sua prioridade hoje?</h2>
      </div>

      <div className='professionalButtonsf'>
      <Link to="/files/safety.pdf" target="_blank" download className='button-img2'>
        <div className='button-img-description'>
          <h3>Safety</h3>
          <h5>Gestão Segurança no Trabalho</h5>
        </div>
      </Link>

      <Link to="/files/performance.pdf" target="_blank" download className='button-img3'>
        <div className='button-img-description'>
          <h3>Cultura de Performance</h3>
          <h5>Gestão Método e Resultado</h5>
        </div>
      </Link>

      </div>
    </section >
  )
}
